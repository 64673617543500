import Information from '@reusable-modules/Information'
import LazyComponent from '@components/LazyComponent'

const InformationComponent = (props) => (
  <LazyComponent
    id={props.id}
    placeholderType={5}
  ><Information {...props} />
  </LazyComponent>
)

export default InformationComponent