import { FC, ReactChild } from 'react'
import shortid from 'shortid'
import styled from 'styled-components'
import { MediaPlaceholder } from '@components'
import { Container, Row, Col, Button } from '@mch-group/uikit-components'
import CallToAction from '@reusable-modules/common/CallToAction'
import { detectDpr } from '@utils'

import CustomLinkTag from '../CustomLinkTag'
import LazyImage from '../Image'
import Text from '../Text'
import cssClasses from '../../constants/cssClasses'

interface IRmInformationContent {
  title?: string
  subTitle?: string
  image?: string
  cta_href: string
  cta_label?: string
  body?: ReactChild
}

interface IRmInformation {
  className: string
  info_grp1_column_num: string
  id?: string
  moduleName?: string,
  info_grp5_global_cta_url?: string,
  info_grp5_show_global_cta?: string
}

const RmInformation: FC<IRmInformation> = ({ ...props }) => {
  const columnNumber = parseInt(props.info_grp1_column_num, 10) || 0
  let formatedObject = []
  //@ts-ignore
  formatedObject = new Array(columnNumber).fill().map((key, i) => {
    const number = i + 1
    return {
      title: props[`info_grp${number}_title`],
      subTitle: props[`info_grp${number}_subtitle`],
      image: props[`info_grp${number}_img`],
      body: props[`info_grp${number}_body`],
      cta_label: props[`info_grp${number}_cta_label`],
      cta_href: props[`info_grp${number}_cta_href`]
    }
  })

  return (
    //@ts-ignore
    <section id={props.id} className={`${props.moduleName} ${cssClasses.CMS_MODULE}`}>
      <Container className={'px-5 px-lg-8 px-md-7'}>
        <Row>
          {formatedObject.map((item:IRmInformationContent) => (
            <Col
              key={shortid.generate()}
              className={`col-lg-${columnNumber === 1 ? 8 : 12 / columnNumber} col-md-${
                columnNumber === 1 ? 12 : 6
              } col-12`}
            >
              {/* @ts-ignore */}
              <div className='story-card fixed card equal-height'>
                {(item.title || item.subTitle || item.image) && item.image && (
                  <CustomLinkTag url={item.cta_href} title={item.title || item.cta_label}>
                    <MediaPlaceholder aspectRatio='16:10' className='mb-6'>
                      <LazyImage
                        quality='auto:best'
                        publicId={item.image}
                        crop='fill'
                        aspect_ratio='16:10'
                        alt={item.title || item.cta_label || item.subTitle}
                        responsive
                        preload='lqip'
                        dpr={detectDpr('dpr_')}
                      />
                    </MediaPlaceholder>
                  </CustomLinkTag>
                )}
                <div className='card-body'>
                  {item.title && <h2 className='card-title h4'>{item.title}</h2>}
                  {item.subTitle && <p className='card-date  card-text'>{item.subTitle}</p>}
                  {item.body && (
                    <StyledText className='card-description card-text'>
                      <Text isHtml>{item.body}</Text>
                    </StyledText>
                  )}
                  {item.cta_label && (
                    <div className='card-cta'>
                      {/** @ts-ignore */}
                      <CallToAction arrowFloatedRight href={item.cta_href} hrefAs={item.cta_href}>
                        {item.cta_label}
                      </CallToAction>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {props.info_grp5_global_cta_url && props.info_grp5_show_global_cta === 'true' &&
        <CustomLinkTag url={props.info_grp5_global_cta_url} ariaLabel={props.info_grp5_global_cta_url}>
          <div className='card-cta d-flex justify-content-center mt-10'>
            {/** @ts-ignore */}
            <Button variant='secondary'>
              {props['info_grp5_global_cta_label']}
            </Button>
          </div>
          </CustomLinkTag>
          }
      </Container>
    </section>
  )
}

const StyledText = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`

export default RmInformation
